<template>
  <b-table hover :items="files" :fields="fields">
    <template #cell(thumb_url)="data">
      <img :src="data.item.thumb_url" style="width: 160px;" alt="">
    </template>
    <template #cell(name)="data">
      <b-link :href="data.item.url" target="_blank">{{data.item.name}}</b-link>
      <br>
      <span>{{ data.item.url }}</span>
    </template>
    <template #cell(size)="data">
      <span>{{ formatBytes(data.item.size) }}</span>
    </template>
    <template #cell(actions)="data">
      <b-button @click="deleteFile(data.item.id)" variant="link">Delete</b-button>
    </template>
  </b-table>
</template>

<script>
import api from '@/api'

export default {
  data: () => ({
    fields: [
      {
        key: 'id',
        label: 'ID',
        sortable: true
      },
      {
        key: 'thumb_url',
        label: 'Thumbnail'
      },
      {
        key: 'name',
        label: 'Filename'
      },
      {
        key: 'size',
        label: 'File size',
        sortable: true
      },
      {
        key: 'date_uploaded',
        label: 'Uploaded Date',
        sortable: true
      },
      {
        key: 'actions',
        label: 'Actions'
      }
    ],
    files: []
  }),
  mounted () {
    this.getFiles()
  },
  methods: {
    getFiles: async function () {
      let files = await api.admin.media.getFiles()
      if (files) {
        this.files = files
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    deleteFile: async function (media_id) {
      let r = await api.admin.media.deleteFile(media_id)
      if (r.deleted) {
        this.$router.go()
      }
    }
  }
}
</script>

<style scoped>
</style>
